import ImgCoffee from '../images/offers/coffee.png';
import ImgEvCharging from '../images/offers/ev-charging.png';
import ImgFueling from '../images/offers/fuel.png';
import ImgTrailer from '../images/offers/trailer.png';

export const OffersIllustrationsMap = {
    'illustration-coffee': ImgCoffee,
    'illustration-ev-charging': ImgEvCharging,
    'illustration-fueling': ImgFueling,
    'illustration-trailer': ImgTrailer,
};