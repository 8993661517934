import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import {
    getWeek,
    startOfYear,
    startOfMonth,
    startOfWeek,
    startOfDay,
} from 'date-fns';

import { Grid, GridCell, GridInner } from "@rmwc/grid";
import BaseFormComponent from "./BaseFormComponent";
import Loader from "./Loader";
import Tabs from "./Tabs";
import { createMonthPresets, createWeekPresets, createYearPresets } from "./DatePreset";
import SelectScroll from "./SelectScroll";
import DatePicker from './DatePicker';

export const PeriodTypes = {
    Day: "day",
    Week: "week",
    Month: "month",
    Year: "year"
};
class EnergyFilter extends BaseFormComponent {

    constructor(props) {
        super(props);
        const now = new Date();
        const yearPresets = createYearPresets();
        this.state = {
            periodTypes: [
                { type: PeriodTypes.Day, label: this.props.intl.formatMessage({ id: "Filter.Period.Day" }) },
                { type: PeriodTypes.Week, label: this.props.intl.formatMessage({ id: "Filter.Period.Week" }) },
                { type: PeriodTypes.Month, label: this.props.intl.formatMessage({ id: "Filter.Period.Month" }) },
                { type: PeriodTypes.Year, label: this.props.intl.formatMessage({ id: "Filter.Period.Year" }) },
            ],
            weekPresets: createWeekPresets(this.props.intl, new Date()),
            monthPresets: createMonthPresets(this.props.intl, new Date()),
            yearPresets: yearPresets,
            filter: {
                startDate: this.props.filter.startDate,
                selectedPeriodType: this.props.filter.selectedPeriodType,
            },
            selectedYear: startOfYear(now),
            selectedYearIndex: yearPresets.findIndex(x => +x.date.getFullYear() === +now.getFullYear()),
            selectedDay: startOfDay(now),
            selectedMonth: startOfMonth(now),
            selectedMonthIndex: now.getMonth(),
            selectedWeek: startOfWeek(now, { weekStartsOn: 1 }),
            selectedWeekIndex: getWeek(now, { weekStartsOn: 1 }) - 1 // because week numbers start from 1, but we need index of week
        };

        this.getSelectedValueForPeriodType = this.getSelectedValueForPeriodType.bind(this);
        this.onPeriodTypeChange = this.onPeriodTypeChange.bind(this);
        this.onWeekSelected = this.onWeekSelected.bind(this);
        this.onMonthSelected = this.onMonthSelected.bind(this);
        this.onYearSelected = this.onYearSelected.bind(this);
        this.onDaySelected = this.onDaySelected.bind(this);
        this.onYearSelectedRelative = this.onYearSelectedRelative.bind(this);
        this.yearChanged = this.yearChanged.bind(this);
        this.filter = this.filter.bind(this);
    }

    getSelectedValueForPeriodType(type) {
        const { selectedWeek, selectedMonth, selectedYear, selectedDay } = this.state;

        switch (type) {
            case PeriodTypes.Day: return selectedDay;
            case PeriodTypes.Week: return selectedWeek;
            case PeriodTypes.Month: return selectedMonth;
            case PeriodTypes.Year: return selectedYear;
            default: return new Date();
        }
    }

    onPeriodTypeChange(index) {
        const { periodTypes } = this.state;
        const selectedType = periodTypes[index].type;
        const startDate = this.getSelectedValueForPeriodType(selectedType);

        this.setState((prev) => ({
            ...prev,
            filter: {
                ...prev.filter,
                startDate: moment(startDate),
                selectedPeriodType: selectedType
            }
        }), this.filter);
    }

    filter() {
        this.props.onSubmit(this.state.filter);
    }

    onWeekSelected(index) {
        const { weekPresets } = this.state;
        const startDate = weekPresets[index].date;

        this.setState((prev) => ({
            ...prev,
            selectedWeek: startDate,
            selectedWeekIndex: index,
            filter: {
                ...prev.filter,
                startDate: moment(startDate)
            }
        }), this.filter);
    }

    onDaySelected(day) {
        this.setState((prev) => ({
            ...prev,
            selectedDay: day,
            filter: {
                ...prev.filter,
                startDate: moment(day)
            }
        }), this.filter);
    }

    onMonthSelected(index) {
        const { monthPresets } = this.state;
        const startDate = monthPresets[index].date;

        this.setState((prev) => ({
            ...prev,
            selectedMonth: startDate,
            selectedMonthIndex: index,
            filter: {
                ...prev.filter,
                startDate: moment(startDate)
            }
        }), this.filter);
    }

    yearChanged(index, yearStartDate) {
        const { yearPresets, selectedWeekIndex, selectedMonth } = this.state;
        const startDate = yearPresets[index].date;
        const weekPresets = createWeekPresets(this.props.intl, startDate);

        this.setState((prev) => ({
            ...prev,
            selectedYear: startDate,
            selectedYearIndex: index,
            selectedWeek: weekPresets[selectedWeekIndex].date,
            selectedMonth: moment(selectedMonth).set('year', yearPresets[index].value).toDate(),
            weekPresets: weekPresets,
            monthPresets: createMonthPresets(this.props.intl, startDate),
            filter: {
                ...prev.filter,
                startDate: yearStartDate
            }
        }), this.filter);
    }
    
    onYearSelectedRelative(index) {
        const { yearPresets } = this.state;
        const currentStartDate = this.state.filter.startDate.set('year', yearPresets[index].value);
        this.yearChanged(index, currentStartDate)
    }

    
    onYearSelected(index) {
        const { yearPresets } = this.state;
        const startDate = yearPresets[index].date;
        this.yearChanged(index, moment(startDate))
    }

    renderDayFilterTab() {
        const { selectedDay } = this.state;
        return (
            <GridInner className="mt-25">
                <GridCell desktop={3} tablet={2} />
                <GridCell desktop={6} tablet={4} phone={4} className="d-flex justify-content-center">
                    <DatePicker
                        field="day"
                        label={null}
                        onChange={this.onDaySelected}
                        value={selectedDay}
                    />
                </GridCell>
            </GridInner>
        );
    }

    renderWeekFilterTab() {
        const { selectedWeekIndex, weekPresets, selectedYearIndex, yearPresets } = this.state;
        const selectedWeekName = weekPresets[selectedWeekIndex].label;
        const selectedYearName = yearPresets[selectedYearIndex].label;

        return (
            <GridInner className="mt-25">
                <GridCell desktop={3} tablet={2} />
                <GridCell desktop={6} tablet={4} phone={4} className="d-flex justify-content-center">
                    <SelectScroll
                        field="week"
                        label={null}
                        onChange={this.onWeekSelected}
                        onLeftClick={(index, prevIndex) => console.log("clicked left, week", index, "->", prevIndex)}
                        onRightClick={(index, prevIndex) => console.log("clicked right, week", index, "->", prevIndex)}
                        value={selectedWeekName}
                        options={weekPresets}
                        className="mdc-select--no-margin">
                    </SelectScroll>
                    <SelectScroll
                        field="year"
                        label={null}
                        onChange={this.onYearSelectedRelative}
                        onLeftClick={(index, prevIndex) => console.log("clicked left, year", index, "->", prevIndex)}
                        onRightClick={(index, prevIndex) => console.log("clicked right, year", index, "->", prevIndex)}
                        value={selectedYearName}
                        options={yearPresets}>
                    </SelectScroll>
                </GridCell>
            </GridInner>
        );
    }

    renderMonthFilterTab() {
        const { selectedMonthIndex, monthPresets, selectedYearIndex, yearPresets } = this.state;
        const selectedMonthName = monthPresets[selectedMonthIndex].label;
        const selectedYearName = yearPresets[selectedYearIndex].label;
        return (
            <GridInner className="mt-25">
                <GridCell desktop={3} tablet={2} />
                <GridCell desktop={6} tablet={4} phone={4} className="d-flex justify-content-center">
                    <SelectScroll
                        field="month"
                        label={null}
                        onChange={this.onMonthSelected}
                        onLeftClick={(index, prevIndex) => console.log("clicked left, month", index, "->", prevIndex)}
                        onRightClick={(index, prevIndex) => console.log("clicked right, month", index, "->", prevIndex)}
                        value={selectedMonthName}
                        options={monthPresets}
                        className="mdc-select--no-margin">
                    </SelectScroll>
                    <SelectScroll
                        field="year"
                        label={null}
                        onChange={this.onYearSelectedRelative}
                        onLeftClick={(index, prevIndex) => console.log("clicked left, year", index, "->", prevIndex)}
                        onRightClick={(index, prevIndex) => console.log("clicked right, year", index, "->", prevIndex)}
                        value={selectedYearName}
                        options={yearPresets}>
                    </SelectScroll>
                </GridCell>
            </GridInner>
        );
    }

    renderYearFilterTab() {
        const { selectedYearIndex, yearPresets } = this.state;
        const selectedYearName = yearPresets[selectedYearIndex].label;
        return (
            <GridInner className="mt-25">
                <GridCell desktop={3} tablet={2} />
                <GridCell desktop={6} tablet={4} phone={4} className="d-flex justify-content-center">
                    <SelectScroll
                        field="year"
                        label={null}
                        onChange={this.onYearSelected}
                        onLeftClick={(index, prevIndex) => console.log("clicked left, year", index, "->", prevIndex)}
                        onRightClick={(index, prevIndex) => console.log("clicked right, year", index, "->", prevIndex)}
                        value={selectedYearName}
                        options={yearPresets}>
                    </SelectScroll>
                </GridCell>
            </GridInner>
        );
    }

    render() {
        const { periodTypes, filter: { selectedPeriodType } } = this.state;
        const { loading } = this.props;

        const tabContentsMap = {
            [PeriodTypes.Day]: this.renderDayFilterTab(),
            [PeriodTypes.Week]: this.renderWeekFilterTab(),
            [PeriodTypes.Month]: this.renderMonthFilterTab(),
            [PeriodTypes.Year]: this.renderYearFilterTab()
        };
        return (
            <Grid className={classnames("filter-form", { "filter-form--open": this.props.open })}>

                {loading &&
                    <Loader type={Loader.TYPE_COVER} />}

                <GridCell span={12}>
                    <Tabs
                        defaultTabIndex={periodTypes.findIndex(x => x.type === selectedPeriodType)}
                        items={periodTypes.map((x, i) => ({
                            label: x.label,
                            contents: tabContentsMap[x.type],
                            onSelect: this.onPeriodTypeChange
                        }))} />
                </GridCell>
            </Grid >
        );
    }
}

EnergyFilter.propTypes = {
    open: PropTypes.bool,
    toggle: PropTypes.func,
    onSubmit: PropTypes.func,
    filter: PropTypes.object,
    loading: PropTypes.bool
};

export default injectIntl(EnergyFilter);
