import {Grid, GridCell} from "@rmwc/grid";
import BaseDialog from "../../components/common/BaseDialog";
import {Typography} from "@rmwc/typography";
import {FormattedHTMLMessage, FormattedMessage, useIntl} from "react-intl";
import {Button} from "@rmwc/button";
import {OffersIllustrationsMap} from "../../helpers/offersHelper";
import {useEffect, useState} from "react";
import {CollapsibleList, SimpleListItem} from "@rmwc/list";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/common/Loader";
import {useValidator} from "../../helpers/hooks";
import {insertOrUpdateOffer} from "../../actions/offersActions";
import {OfferBackgroundColorsMap} from "../../helpers/offerBackgroundColorsHelper";

const defaultIllustration = Object.keys(OffersIllustrationsMap)[0];
const imageSize = 50;

const OfferDialog = ({isOpen, onClose, item, onSubmitted}) => {
    const [isIllustrationListOpen, setIsIllustrationListOpen] = useState(false);
    const [isBackgroundColorListOpen, setIsBackgroundColorListOpen] = useState(false);
    const [offerItem, setOfferItem] = useState();

    const upsertOffer = useSelector(state => state.upsertOffer);
    const locales = JSON.parse(localStorage.getItem("etmessages"));

    const intl = useIntl();
    const dispatch = useDispatch();
    const [validator] = useValidator({
        alxAppTranslation: {
            message: intl.formatMessage({id: "Offers.Dialog.Validation.AlxAppTranslation"}),
            rule: (val, _, validator) => {
                return validator.helpers.testRegex(val, /^(AlxApp\.).+/);
            }
        },
        keyExists: {
            message: intl.formatMessage({id: "Offers.Dialog.Validation.NoSuchKey"}),
            rule: (val) => {
                return Object.keys(locales).indexOf(val) > -1;
            }
        }
    });

    useEffect(() => {
        if (item) {
            setOfferItem(item);
        }
    }, [item]);

    const onListItemClicked = (illustration) => {
        setOfferItem(prev => ({...prev, illustration}));
        setIsIllustrationListOpen(false);
    }

    const onColorListItemClicked = (backgroundColor) => {
        setOfferItem(prev => ({...prev, backgroundColor}));
        setIsBackgroundColorListOpen(false);
    }

    const onInsertOrUpdate = () => {
        if (!validator.allValid()) {
            validator.showMessages();
            return;
        }

        if (!offerItem.illustration) {
            offerItem.illustration = defaultIllustration;
        }

        dispatch(insertOrUpdateOffer(offerItem))
            .then(() => onSubmitted?.(true))
            .catch(() => onSubmitted?.(false));

        setOfferItem(null);
    }

    const onCloseDialog = () => {
        setOfferItem(null);
        onClose();
    }

    if (upsertOffer.fetching) {
        return <Loader type={Loader.TYPE_CENTER}/>;
    }

    return (
        <BaseDialog isOpen={isOpen} onClose={onCloseDialog} className="mdc-dialog--simple offers">
            <Grid>
                <GridCell span={12}>
                    <Typography use="headline2">
                        {item
                            ? <FormattedMessage id="Offers.Dialog.Title.Edit"/>
                            : <FormattedMessage id="Offers.Dialog.Title.New"/>
                        }
                    </Typography>
                </GridCell>
                <GridCell span={12}>
                    <FormattedHTMLMessage id="Offers.Instructions"/>
                </GridCell>
                <GridCell span={12}>
                    <Typography use="body1" className="icon-label">
                        <FormattedMessage id="Offers.Dialog.Icon"/>
                    </Typography>
                    <CollapsibleList
                        handle={
                            <SimpleListItem className="illustration" onClick={() => setIsIllustrationListOpen(true)}>
                                <img
                                    width={imageSize}
                                    height={imageSize}
                                    src={OffersIllustrationsMap[offerItem?.illustration ?? defaultIllustration]}
                                    alt={offerItem?.illustration ?? defaultIllustration}
                                />
                            </SimpleListItem>
                        }
                        open={isIllustrationListOpen}
                    >
                        {Object.keys(OffersIllustrationsMap).map((img) => (
                            <SimpleListItem className="illustration" key={img} onClick={() => onListItemClicked(img)}>
                                <img width={imageSize} height={imageSize} src={OffersIllustrationsMap[img]}
                                     alt={img}/>
                            </SimpleListItem>
                        ))}
                    </CollapsibleList>
                </GridCell>
                <GridCell span={12}>
                    <Typography use="body1" className="icon-label">
                        <FormattedMessage id="Offers.Dialog.BackgroundColor"/>
                    </Typography>
                    <CollapsibleList
                        handle={
                            <SimpleListItem onClick={() => setIsBackgroundColorListOpen(true)}>
                                <div
                                    style={{width: '100%', backgroundColor: OfferBackgroundColorsMap[offerItem?.backgroundColor ?? OfferBackgroundColorsMap[0]]}}>
                                    {offerItem?.backgroundColor}
                                </div>
                            </SimpleListItem>
                        }
                        open={isBackgroundColorListOpen}
                    >
                        {Object.keys(OfferBackgroundColorsMap).map((color) => (
                            <SimpleListItem key={color} onClick={() => onColorListItemClicked(color)}>
                                <div style={{width: '100%', backgroundColor: OfferBackgroundColorsMap[color]}}>{color}</div>
                            </SimpleListItem>
                        ))}
                    </CollapsibleList>
                </GridCell>
                <GridCell span={12}>
                    <TextFieldGroup
                        field="titleKey"
                        rules="required|alxAppTranslation|keyExists"
                        validator={validator}
                        onChange={(e) => {
                            e.persist();
                            setOfferItem(prev => ({...prev, titleKey: e.target.value}));
                        }}
                        value={offerItem?.titleKey ?? ""}
                        label="Offers.Dialog.TitleKey"
                    />
                </GridCell>
                <GridCell span={12}>
                    <TextFieldGroup
                        field="textKey"
                        rules="required|alxAppTranslation|keyExists"
                        validator={validator}
                        onChange={(e) => {
                            e.persist();
                            setOfferItem(prev => ({...prev, textKey: e.target.value}));
                        }}
                        value={offerItem?.textKey ?? ""}
                        label="Offers.Dialog.TextKey"
                    />
                </GridCell>
                <GridCell span={12}>
                    <TextFieldGroup
                        field="textKey"
                        rules="required"
                        validator={validator}
                        onChange={(e) => {
                            e.persist();
                            setOfferItem(prev => ({...prev, okkDiscountCouponId: e.target.value}));
                        }}
                        value={offerItem?.okkDiscountCouponId ?? ""}
                        label="Offers.Dialog.OkkDiscountCouponId"
                    />
                </GridCell>
                <GridCell span={12}>
                    <Button
                        type="submit"
                        unelevated
                        onClick={onInsertOrUpdate}
                        className="mdc-button--primary mt-20"
                    >
                        <FormattedMessage id="Offers.Dialog.SubmitBtn"/>
                    </Button>
                </GridCell>
            </Grid>
        </BaseDialog>
    );
}

export default OfferDialog;