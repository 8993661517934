import React from "react";
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import moment from "moment";

import { Button } from "@rmwc/button";
import { Grid, GridCell } from "@rmwc/grid";

import auth from "../../auth/authenticate";
import ElectricityInvoicesFilter from "./ElectricityInvoicesFilter";

import { fetchElectricityInvoices } from "../../actions/invoiceActions";
import { getInvoiceDetailsPath } from "../../const/routes";
import { Statuses } from "../../const/Invoice";
import { isInvoiceNotPaidAndDueDateExceeded } from "../../helpers/invoiceHelper";

import AlertMessage from "../common/AlertMessage";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import Alert from "../common/Alert";
import Number from "../common/Number";
import InvoiceStatus from "../Invoice/InvoiceStatus";
import FilterButton from "../common/FilterButton";
import BaseFormComponent from "../common/BaseFormComponent";
import { IsLatvianWeb } from "../../helpers/AlternativeDesign";

class ElectricityInvoicesContainer extends BaseFormComponent {

    constructor(props) {
        super(props);

        this.toggleFilter = this.toggleFilter.bind(this);
        this.fetchInvoices = this.fetchInvoices.bind(this);
        this.onFilterSubmit = this.onFilterSubmit.bind(this);
        this.renderInvoiceRow = this.renderInvoiceRow.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.scrollToRef = React.createRef();

        this.initialState = {
            selectedPage: 1,
            isFilterOpen: true,
            filter: {
                startDate: moment().subtract(12, "months").startOf("month"),
                endDate: moment()
            },
        };
        this.state = {
            ...this.initialState,
            isLatvianWeb: IsLatvianWeb(),
        };
    }

    componentDidMount() {
        this.fetchInvoices(this.state.selectedPage, this.state.filter)
            .finally(this.props.didFinishInitialLoad);
    }

    toggleFilter = () => {
        this.setState(prev => ({ isFilterOpen: !prev.isFilterOpen }));
    };

    onFilterSubmit(filter) {
        let page = 1;
        this.setState({ selectedPage: page, filter: filter });
        this.fetchInvoices(page, filter);
    }

    fetchInvoices(page, filter) {
        return this.props.fetchElectricityInvoices(
            page,
            auth.getPageSize(),
            filter.startDate.format("YYYY-MM-DD 00:00:00"),
            filter.endDate.format("YYYY-MM-DD 23:59:59"));
    }

    invoiceStatusTranslation(status) {
        switch (status) {
            case Statuses.StatusPaid:
                return "Invoices.Paid";
            case Statuses.StatusPartiallyPaid:
                return "Invoices.PartiallyPaid";
            case Statuses.StatusPaymentPending:
                return "Invoices.PaymentPending";
            case Statuses.StatusCredited:
                return "Invoices.Credited";
            default:
                return "Invoices.NotPaid";
        }
    }

    canPayInvoice(invoice) {
        return invoice.status !== Statuses.StatusPaid &&
            invoice.status !== Statuses.StatusCredited &&
            invoice.status !== Statuses.StatusPaymentPending &&
            invoice.isLatestInvoice &&
            +invoice.subTotalWithVat > 0;
    }

    onPageChange({ page }) {
        this.setState({ selectedPage: page }, this.fetchInvoices.bind(this, page, this.state.filter));
    }

    groupInvoicesByMonth(invoices) {
        let groupedInvoices = {};
        for (let i in invoices) {
            let invoice = invoices[i];
            let groupKey = moment(invoice.periodEnd).format("YYYY-MM-01");

            if (!groupedInvoices[groupKey]) {
                groupedInvoices[groupKey] = {
                    invoices: [],
                    areAllPaid: true
                };
            }

            if (invoice.status !== Statuses.StatusPaid) {
                groupedInvoices[groupKey].areAllPaid = false;
            }

            groupedInvoices[groupKey].invoices.push(invoice);
        }

        return groupedInvoices;
    }

    renderInvoicesHeader() {
        const showFilter = this.state.isFilterOpen;

        return (
            <>
                <GridCell span={12}>
                    <FilterButton
                        className="right"
                        active={showFilter}
                        callback={this.onChangeByField.bind(this, 'isFilterOpen')}
                    />
                </GridCell>
                <GridCell span={12}>
                    <ElectricityInvoicesFilter
                        open={showFilter}
                        toggle={this.toggleFilter}
                        onSubmit={this.onFilterSubmit}
                        filter={this.state.filter}
                    />
                </GridCell>
                {this.renderInvoicesMessages()}
            </>
        );
    }

    renderInvoicesMessages = () => {
        const invoicesNotFound = !this.props.areInvoicesLoading &&
            this.props.areInvoicesFetched &&
            (!this.props.invoices || this.props.invoices.length < 1);

        return (invoicesNotFound &&
            <GridCell span={12}>
                <Alert title={<FormattedMessage id="Invoices.NotFound" />} />
            </GridCell>
        );
    }

    renderInvoicesPeriods = invoices => {
        if (!this.props.invoices || this.props.invoices.length < 1) {
            return null;
        }

        let grouped = this.groupInvoicesByMonth(invoices.filter(x => !x.isSmallProducerInvoice));
        let months = [];

        for (let key in grouped) {
            const periodStart = key && moment(key);
            let invoices = grouped[key].invoices;
            let areAllPaid = grouped[key].areAllPaid;

            months.push(
                <table className="table table--primary table--accordion mb-30"
                    key={"period" + key}
                >
                    <thead>
                        <tr className={classnames(
                            { "mdc-theme--primary": !areAllPaid },
                            { disabled: areAllPaid }
                        )}
                        >
                            <th className="w-25 capitalize">
                                {periodStart.format("MMMM YYYY")}
                            </th>
                            <th colSpan="4"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map(this.renderInvoiceRow)}
                    </tbody>
                </table>
            );
        }

        return months;
    }

    renderInvoiceRow(invoice, key) {
        const isInvoicePaid = invoice.status === Statuses.StatusPaid || invoice.status === Statuses.StatusCredited;
        const isPaymentPending = invoice.status === Statuses.StatusPaymentPending;
        const invoiceDeadline = invoice.deadline && moment(invoice.deadline);
        const invoiceNotPaidAndDueDateExceeded = isInvoiceNotPaidAndDueDateExceeded(invoice.status, invoice.deadline);

        const priceUnit = invoice.priceUnit ? invoice.priceUnit : "€";

        const invoiceDetailsLink = getInvoiceDetailsPath(invoice.id, invoice.type);

        return (

            <tr
                className={classnames(
                    { disabled: isInvoicePaid || isPaymentPending },
                )}
                key={"invoice" + invoice.id + "_" + key}
                onClick={() => this.props.history.push(invoiceDetailsLink)}
            >
                <td className="primary">
                    <div>
                        {!this.state.isLatvianWeb && (
                            <InvoiceStatus
                                status={invoice.status}
                                deadline={invoice.deadline}
                                showText={false}
                                inline
                                className="mr-12" />
                        )}
                        <span>
                            <FormattedMessage id="Invoices.Table.InvoiceNumber" />
                            &nbsp;
                            {invoice.number}
                        </span>
                    </div>
                    <div className="hidden-above-sm">
                        {invoiceDeadline
                            ? invoiceDeadline.format("DD.MM.YYYY")
                            : "-"}
                    </div>
                </td>
                <td className="hidden-above-sm d-flex justify-content-end align-items-end">
                    {invoice.sum !== invoice.subTotalWithVat &&
                        <span className="amount total">
                            <Number value={invoice.sum} unit={priceUnit} />
                        </span>
                    }
                    <span className="amount to-be-paid">
                        <Number value={invoice.totalToBePaid} unit={priceUnit} />
                    </span>
                </td>
                <td className="text-right toggle-hidden hidden-below-sm">
                    <p className="nowrap">
                        <FormattedMessage id="Invoices.Table.Sum"/>:
                        &nbsp;
                        <span className="amount">
                            <Number value={invoice.sum} unit={priceUnit}/>
                        </span>
                    </p>
                    <p className="nowrap">
                        <FormattedMessage id="Invoices.Table.ToBePaid" />
                        &nbsp;
                        <span className="amount">
                            <Number value={invoice.totalToBePaid} unit={priceUnit} />
                        </span>
                    </p>
                </td>
                <td className="hidden-below-sm">
                    <p className="previous toggle-hidden">
                        <FormattedMessage id="Invoices.Table.DueDate" />
                    </p>
                    <p className="last">
                        {invoiceDeadline
                            ? invoiceDeadline.format("DD.MM.YYYY")
                            : "-"}
                    </p>
                </td>
                {!this.state.isLatvianWeb && (
                    <td className={classnames('toggle-hidden hidden-below-sm',
                        {'mdc-theme--alert': invoiceNotPaidAndDueDateExceeded})}>
                        <FormattedMessage
                            id={this.invoiceStatusTranslation(invoice.status)}/>
                    </td>
                )}
                <td className="text-right toggle-hidden hidden-below-sm action">
                    <Button href={invoiceDetailsLink} tag="a" className="mdc-button--link mdc-theme--secondary mdc-button--small">
                        {this.canPayInvoice(invoice)
                            ? <FormattedMessage id="Invoices.Table.OpenInvoiceAndPay" />
                            : <FormattedMessage id="Invoices.Table.OpenInvoice" />}
                    </Button>
                </td>

            </tr>
        );
    }

    render() {
        return (
            <Grid className="mdc-layout-grid--base mb-60 pt-10">
                <GridCell span={12}>
                    <AlertMessage
                        type={AlertMessage.TYPE_NOTICE}
                        title={<FormattedHTMLMessage id="Invoice.Notice.UpdateDelay" />}
                    />
                </GridCell>
                {this.renderInvoicesHeader()}
                <GridCell span={12} ref={this.scrollToRef}>
                    {this.props.areInvoicesLoading
                        ? <Loader type={Loader.TYPE_CENTER} />
                        : this.renderInvoicesPeriods(this.props.invoices)}
                </GridCell>
                <GridCell span={12}>
                    <Pagination
                        total={this.props.totalInvoices || 0}
                        selectedPage={this.state.selectedPage}
                        onChange={this.onPageChange}
                        scrollToRef={this.scrollToRef}
                    />
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        totalInvoices: state.electricityInvoices.total,
        invoices: state.electricityInvoices.data,
        areInvoicesLoading: state.electricityInvoices.fetching,
        areInvoicesFetched: state.electricityInvoices.fetched
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchElectricityInvoices
    }, dispatch);
}

export default injectIntl(
    withRouter(
        connect(mapStateToProps, matchDispatchToProps)(ElectricityInvoicesContainer)
    )
);
