import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import { FormattedMessage, useIntl } from "react-intl";
import TableList from "../../components/common/TableList";
import { useEffect, useMemo, useState } from "react";
import { OffersIllustrationsMap } from "../../helpers/offersHelper";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import AlertMessage from "../../components/common/AlertMessage";

import "../../styles/components/_offers.scss";
import {getOffers} from "../../actions/offersActions";
import OfferDialog from "./OfferDialog";
import {OfferBackgroundColorsMap} from "../../helpers/offerBackgroundColorsHelper";

const Offers = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState();

    const offers = useSelector(state => state.offers);
    const upsertOffer = useSelector(state => state.upsertOffer);

    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!offers.fetched && !offers.fetching && !offers.error) {
            dispatch(getOffers());
        }
    }, [dispatch, offers]);

    const tableHeaders = useMemo(() => ([
        {
            label: intl.formatMessage({ id: 'Offers.Table.Icon' }),
            colspan: 1,
            className: "text-center",
        },
        {
            label: intl.formatMessage({ id: 'Offers.Table.BackgroundColor' }),
            colspan: 1,
            className: "text-center",
        },
        {
            label: intl.formatMessage({ id: 'Offers.Table.TitleKey' }),
            colspan: 1,
            className: "text-center",
        },
        {
            label: intl.formatMessage({ id: 'Offers.Table.TextKey' }),
            colspan: 1,
            className: "text-center",
        },
        {
            label: intl.formatMessage({ id: 'Offers.Table.OkkDiscountCouponId' }),
            colspan: 1,
            className: "text-center",
        },
        {
            label: null,
            colspan: 1,
        }
    ]), [intl]);

    const getTableRows = () => {
        return offers.data.map((data) => ({
            content: [
                {
                    colspan: 1,
                    content: <img width={50} height={50} src={OffersIllustrationsMap[data.illustration]} alt={data.illustration} />,
                    className: "illustration",
                },
                {
                    colspan: 1,
                    content: data.backgroundColor,
                    className: "text-center",
                    style: {backgroundColor: OfferBackgroundColorsMap[data.backgroundColor]},
                },
                {
                    colspan: 1,
                    content: data.titleValue,
                    className: "text-center",
                },
                {
                    colspan: 1,
                    content: data.textValue,
                    className: "text-center",
                },
                {
                    colspan: 1,
                    content: data.okkDiscountCouponId,
                    className: "text-center",
                },
            ]
        }))
    }

    const onEditRow = (index) => {
        setItemToEdit(offers.data[index]);
        setIsDialogOpen(true);
    }

    const onAddNew = () => {
        setItemToEdit(null);
        setIsDialogOpen(true);
    }

    const onCloseDialog = () => {
        setItemToEdit(null);
        setIsDialogOpen(false);
    }

    const onCampaignSubmitted = (isSuccess) => {
        if (isSuccess) {
            dispatch(getOffers());
        }

        setItemToEdit(null);
    }

    if (offers.fetching) {
        return <Loader type={Loader.TYPE_CENTER} />;
    }

    return (
        <>
            <Grid>
                {upsertOffer.error &&
                    <GridCell span={12}>
                        <AlertMessage
                            isSmall={true}
                            type={AlertMessage.TYPE_ALERT}
                            title={upsertOffer.error ?? intl.formatMessage({ id: "Offers.Error" })}
                        />
                    </GridCell>
                }
                <GridCell span={12}>
                    <Button outlined className="right" onClick={onAddNew}>
                        <i className="icon-plus" />
                        <FormattedMessage id="Offers.AddNewBtn" />
                    </Button>
                </GridCell>
                <GridCell span={12}>
                    <GridInner>
                        <GridCell span={12}>
                            <TableList
                                className="offers"
                                headers={tableHeaders}
                                itemContent={getTableRows()}
                                changeRow={intl.formatMessage({ id: 'Offers.Table.EditBtn' })}
                                changeRowCallback={onEditRow}
                            />
                        </GridCell>
                    </GridInner>
                </GridCell>
            </Grid>

            <OfferDialog
                isOpen={isDialogOpen}
                onClose={onCloseDialog}
                item={itemToEdit}
                onSubmitted={onCampaignSubmitted}
            />
        </>
    );
}

export default Offers;