import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";

import { Button } from "@rmwc/button";
import { Icon } from "@rmwc/icon";
import { formatUnit } from "../../helpers/energyHelpers";

import { UsingApp } from "../../helpers/AlternativeDesign";
import { EnergyType } from "../../const/EnergyType";
import { PeriodTypes } from '../common/EnergyFilter';

class ConsumptionTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: true,
            usingApp: UsingApp()
        }

        this.toggleVisible = this.toggleVisible.bind(this);
        this.downloadCsv = this.downloadCsv.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
    }

    toggleVisible() {
        this.setState(state => ({
            ...state,
            visible: !state.visible
        }));
    }

    renderTableRow(item, index) {
        let mnmt = moment(item.timestamp);
        return (
            <tr key={index}>
                <td>{mnmt.format(this.dateFormat)}</td>
                {this.showTimeColumn &&
                    <td>{mnmt.format("HH")}-{mnmt.add(1, 'hour').format("HH")}</td>}
                {this.showTimeBy15MinutesColumn &&
                    <td>{mnmt.format("HH:mm")}</td>}
                {this.showUnitPrice &&
                    <td>{item.pricePerUnitWithVat}</td>
                }
                <td>{item.amount}</td>
                {this.isGasEnergyType &&
                    <td>{item.amountKwh}</td>
                }
                <td>{item.priceWithVat}</td>
            </tr>
        );
    }

    downloadExcel() {
        const { onExportClick } = this.props;
        onExportClick("xlsx");
    }

    downloadCsv() {
        const { onExportClick } = this.props;
        onExportClick("csv");
    }

    get dateFormat() {
        if (this.props.periodType === "year") { return "MMMM YYYY"; }
        else { return "dd DD.MM.YYYY"; }
    }

    get showUnitPrice() { return !this.props.isTotal; }

    get isGasEnergyType() {
        return this.props.type === EnergyType.Gas;
    }

    get showTimeColumn() {
        return this.props.periodType === PeriodTypes.Week
            || (this.props.periodType === PeriodTypes.Day && this.props.type === EnergyType.Gas);
    }

    get showTimeBy15MinutesColumn() {
        return this.props.periodType === PeriodTypes.Day && this.props.type === EnergyType.Electricity
    }

    get columnCount() {
        // 4 = date, time, amount, price
        return 4 + (this.showUnitPrice ? 1 : 0) - (this.showTimeColumn || this.showTimeBy15MinutesColumn  ? 0 : 1);
    }

    get tableUnitPriceHeaderName() {
        const { type, periodType } = this.props;

        let unitPeriod = "";
        switch (periodType) {
            case "day": unitPeriod = type === EnergyType.Gas ? "Hourly" : "Min15"; break;
            case "week": unitPeriod = type === EnergyType.Gas ? "Daily" : "Hourly"; break;
            case "month": unitPeriod = "Daily"; break;
            case "year": unitPeriod = "Monthly"; break;
            default: break;
        }
        return <FormattedMessage
            id={`ConsumptionTable.Headers.UnitPriceWithVat.${unitPeriod}`} />
    }

    render() {
        const { visible } = this.state;
        const { data, totalAmount, totalAmountKwh, totalPriceWithVat, amountFormat, priceFormat, priceFormatTotal, pricePerUnitFormat } = this.props;

        const amountUnit = formatUnit(amountFormat);
        const priceUnit = formatUnit(priceFormat);
        const pricePerUnitUnit = formatUnit(pricePerUnitFormat);
        const totalPriceUnit = formatUnit(priceFormatTotal);

        return (
            <div className="energy-table">
                <div className="energy-table__toggle" onClick={this.toggleVisible}>
                    <FormattedMessage id="ConsumptionTable.Title" />
                    <Icon icon={{

                        icon: "angle-down",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: (visible && "rotate"),
                    }} />
                </div>

                <div className="table__buttons-container">
                    <Button
                        className={classnames("mdc-button--primary", { "hidden": !visible })}
                        onClick={this.downloadCsv}
                        unelevated
                    >
                        <FormattedMessage id="ConsumptionTable.DownloadCsv" />
                    </Button>
                    <Button
                        className={classnames("mdc-button--primary ml-24 ml-mobile-clear mt-mobile-16", { "hidden": !visible })}
                        onClick={this.downloadExcel}
                        unelevated
                    >
                        <FormattedMessage id="ConsumptionTable.DownloadXlsx" />
                    </Button>
                </div>

                <div className={classnames("table__container", { "hidden": !visible })}>
                    <table className={classnames("table table--light table--sticky-header table--sticky-footer", `table--${this.columnCount}-columns`)}>
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="ConsumptionTable.Headers.Date" />
                                </th>
                                {this.showTimeColumn &&
                                    <th>
                                        <FormattedMessage id="ConsumptionTable.Headers.Time" />
                                    </th>}
                                {this.showTimeBy15MinutesColumn &&
                                    <th>
                                        <FormattedMessage id="ConsumptionTable.Headers.Time15Minutes" />
                                    </th>}

                                {this.showUnitPrice &&
                                    <>
                                        <th>
                                            {this.tableUnitPriceHeaderName} <span>({pricePerUnitUnit})</span>
                                        </th>
                                    </>}
                                <th>
                                    <FormattedMessage id="ConsumptionTable.Headers.Consumption" /> <span>({amountUnit})</span>
                                </th>

                                {this.isGasEnergyType &&
                                    <th>
                                        <FormattedMessage id="ConsumptionTable.Headers.Consumption" /> <span>(kWh)</span>
                                    </th>
                                }
                                <th>
                                    <FormattedMessage id="ConsumptionTable.Headers.Price" /> <span>({priceUnit})</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((x, i) => this.renderTableRow(x, i))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={this.columnCount - 2}></td>
                                <td><b>{totalAmount} {amountUnit}</b></td>
                                {this.isGasEnergyType &&
                                    <td><b>{totalAmountKwh} kWh</b></td>
                                }
                                <td><b>{totalPriceWithVat} {totalPriceUnit}</b></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        );
    }
}

ConsumptionTable.defaultProps = {
    data: [],
}

ConsumptionTable.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(null)]),
    data: PropTypes.arrayOf(
        PropTypes.shape({
            amount: PropTypes.number,
            pricePerUnitWithVat: PropTypes.number,
            priceWithVat: PropTypes.number,
            timestamp: PropTypes.string,
        })
    ),
    isTotal: PropTypes.bool,
    priceFormat: PropTypes.string,
    pricePerUnitFormat: PropTypes.string,
    amountFormat: PropTypes.string,

    totalAmount: PropTypes.number,
    totalPriceWithVat: PropTypes.number,
    priceFormatTotal: PropTypes.string,
    isLoading: PropTypes.bool,
    onExportClick: PropTypes.func,
    periodType: PropTypes.string,
    type: PropTypes.oneOf(Object.values(EnergyType)),
}

export default injectIntl(ConsumptionTable);
