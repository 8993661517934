import React, { useState, useRef, useMemo, useEffect } from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Bar } from 'react-chartjs-2';
import moment from "moment";

import "../../styles/components/_bar-and-line-chart.scss";

const minF = (a, b) => Math.min(a, b);
const maxF = (a, b) => Math.max(a, b);

const getMin = (data, axis) => data && data.length > 0
    ? data
        .flatMap(x => x.points
            .map(p => p[axis])
            .reduce(minF, Number.MAX_SAFE_INTEGER))
        .reduce(minF, Number.MAX_SAFE_INTEGER)
    : 0;
const getMax = (data, axis) => data && data.length > 0
    ? data
        .flatMap(x => x.points
            .map(p => p[axis])
            .reduce(maxF, Number.MIN_SAFE_INTEGER))
        .reduce(maxF, Number.MIN_SAFE_INTEGER)
    : 0;

const mapToLegend = (data) => data
    .map((x, i) =>
    ({
        index: i,
        visible: !x.hidden,
        label: x.label,
        color: x.color,
        unit: x.unit,
    }));

const BarAndLineChart = ({ series, axes, yTickCount, yUnitsAboveGraph, showLegend, precision, precisionOptions }) => {

    const [legend, updateLegend] = useState(mapToLegend(series));
    const chartRef = useRef(null);

    // when series is updated externally
    useEffect(() => {
        const legend = mapToLegend(series);
        updateLegend(legend);
        setChartMetaVisibility(legend);

    }, [series]);

    const getAxisId = (position) => {
        return `axis-${position}`;
    }

    const toggleLegendItem = (legendItemIndex) => {
        const legendNewValue = legend.map((x, i) =>
            i === legendItemIndex
                ? { ...x, visible: !x.visible }
                : x);

        updateLegend(legendNewValue);
        setChartMetaVisibility(legendNewValue, legendItemIndex);
    };

    const setChartMetaVisibility = (legend, index) => {
        const chart = chartRef.current.chartInstance;

        const setVisibility = (index) => {
            const isItemVisible = legend[index].visible;
            const datasetMeta = chart.getDatasetMeta(index);
            datasetMeta.hidden = !isItemVisible;
        }

        if (!!index || index === 0) { // if index is set, only update one item
            setVisibility(index);
        } else { // update all
            for (let i = 0; i < legend.length; i++) {
                setVisibility(i);
            }
        }

        chart.update();
    }

    const visibleSeries = useMemo(() => {
        return series.filter((_, i) => legend.some(x => x.index === i && x.visible));
    }, [legend, series]);

    const xLabels = useMemo(() => series[0].points.map(p => p.x), [series]);

    const chartData = useMemo(() => {

        const commonChartOptions = (d) => ({
            type: d.type,
            label: d.label,
            data: d.points,
            yAxisID: getAxisId(d.alignToAxis)
        });

        const lineChartOptions = (d) => ({
            borderColor: d.color,
            pointHoverBackgroundColor: d.color,
        });

        const barChartOptions = (d) => ({
            backgroundColor: d.color,
        });

        return {
            datasets: series.length > 0
                ? series
                    .map(d => ({
                        ...commonChartOptions(d),
                        ...(d.type === "line"
                            ? lineChartOptions(d)
                            : barChartOptions(d))
                    }))
                : []
        }
    }, [series]);

    const precisionOptionsMap = useMemo(() => ({
        minute: {
            tooltipLabel: "dddd D. MMMM HH:mm",
            barWidth: 0.6,
            xAxisLabel: "dd DD.MM HH:mm",
            ...precisionOptions.minute,
        },
        hour: {
            tooltipLabel: "dddd D. MMMM kk:mm",
            barWidth: 0.6,
            xAxisLabel: "dd DD.MM kk:mm",
            ...precisionOptions.hour,
        },
        day: {
            tooltipLabel: "dddd D. MMMM",
            barWidth: 0.75,
            xAxisLabel: "dd DD.MM",
            ...precisionOptions.day,
        },
        month: {
            tooltipLabel: "MMMM YYYY",
            barWidth: 0.8,
            xAxisLabel: "MMMM",
            ...precisionOptions.month,
        }
    }), [precisionOptions]);

    const maxTicksLimit = () => {
        const labelLengthDivider = xLabels.length > 48 ? 12 : 4;

        switch (precision) {
            case 'month': return 12;
            case 'minute': return 24;
            default: return xLabels.length / labelLengthDivider;
        }
    }

    const bottomAxis = useMemo(() => {
        const labels = xLabels;

        const bottomAxis = axes.find(x => x.position === "bottom");
        const timeLabelFormats = Object.keys(precisionOptionsMap)
            .reduce((acc, key) => {
                acc[key] = precisionOptionsMap[key].xAxisLabel;
                return acc;
            }, {});

        return !!bottomAxis
            ? {
                type: bottomAxis.type,
                display: true,
                position: bottomAxis.position,
                id: getAxisId(bottomAxis.position),
                offset: true,
                gridLines: {
                    offsetGridLines: false,
                },
                ticks: {
                    labels: labels,
                    maxTicksLimit: maxTicksLimit(),
                    maxRotation: 45,
                    minRotation: 45,
                },
                time: {
                    unit: precision === 'minute' ? null : precision,
                    displayFormats: timeLabelFormats,
                },
                ...bottomAxis.options
            }
            : { display: false };
    }, [axes, xLabels, precision, precisionOptionsMap]);

    // Using top axis to conditionally show y axis labels above the chart instead of next to tick values
    const topAxis = useMemo(() => {
        return yUnitsAboveGraph
            ? {
                type: "category",
                position: "top",
                id: getAxisId("top"),
                labels: axes
                    .filter(x => x.position === "left" || x.position === "right")
                    .map(x => x.unit),
                gridLines: {
                    display: false
                }
            }
            : { display: false };
    }, [yUnitsAboveGraph, axes])

    const yAxes = useMemo(() => {
        const getYStep = (series, ticks) => {
            let max = getMax(series, "y");
            const min = getMin(series, "y");
            max += min; // Add bit padding between line and graph top

            if (max * 4 <= ticks) {
                return Math.ceil((max * 4) / ticks) / 4
            }

            if (max * 2 <= ticks) {
                return Math.ceil((max * 2) / ticks) / 2;
            }

            return Math.ceil(max / ticks);
        }

        const getYAxisTicksConfig = (axis) => {
            const defaultConfig = {
                beginAtZero: true,
                stepSize: 1,
                suggestedMax: 1
            };

            const axisData = visibleSeries
                .filter(x => x.alignToAxis === axis.position);

            const stepSize = getYStep(axisData, yTickCount);

            return stepSize === 0
                ? defaultConfig
                : {
                    ...defaultConfig,
                    stepSize: stepSize,
                    suggestedMax: yTickCount * stepSize
                }
        }

        return axes
            .filter(x => x.position === "left" || x.position === "right")
            .map(a => ({
                type: a.type,
                position: a.position,
                display: true,
                id: getAxisId(a.position),
                ticks: {
                    callback: (v) => yUnitsAboveGraph ? v : `${v} ${a.unit}`,
                    ...getYAxisTicksConfig(a),
                },
                ...a.options
            }));
    }, [axes, visibleSeries, yTickCount, yUnitsAboveGraph]);


    const options = useMemo(() => {
        const getDataVariableByLabel = (label, varName) => {
            const item = series.find(x => x.label === label);
            return item[varName];
        }

        const precisionOptions = precisionOptionsMap[precision];

        return {
            responsive: true,
            maintainAspectRatio: false,

            datasets: {
                line: {
                    fill: false,
                    borderCapStyle: "butt",
                    spanGaps: true,
                    borderWidth: 2,

                    pointRadius: 0,
                    pointHitRadius: 4,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 4,
                },
                bar: {
                    fill: true,
                    barPercentage: precisionOptions.barWidth,
                    categoryPercentage: 1.0
                }
            },
            scales: {
                xAxes: [bottomAxis, topAxis],
                yAxes: yAxes
            },
            legend: {
                display: false,
                position: "top",
                align: "start",
            },
            tooltips: {
                mode: "index",
                intersect: false,
                callbacks: {
                    title: (item, _) => moment(item[0].label).format(precisionOptions.tooltipLabel),
                    label: (item, data) => {
                        const index = item.datasetIndex;

                        let label = data.datasets[index].label || '';
                        const unit = getDataVariableByLabel(label, "unit") || '';

                        if (label) { label += ': '; }

                        const value = item.yLabel;

                        return `${label}${value} ${unit}`;
                    },
                    labelColor: (item, chart) => {
                        const index = item.datasetIndex;
                        const label = chart.config.data.datasets[index].label || '';

                        const color = getDataVariableByLabel(label, "color") || chart.config.options.defaultColor;

                        return {
                            borderColor: color,
                            backgroundColor: color
                        };
                    }
                }
            },
        }
    }, [bottomAxis, topAxis, yAxes, series, precision, precisionOptionsMap]);

    return (
        <div className="chart">
            {showLegend &&
                <div className="chart__legend">
                    {legend.map((x, i) => (
                        <span
                            key={i}
                            className={classnames("chart__legend__item",
                                { "chart__legend__item--disabled": !x.visible }
                            )}
                            onClick={toggleLegendItem.bind(this, i)}>
                            <span className="chart__legend__item__color" style={{ backgroundColor: x.color }}></span>
                            <span className="chart__legend__item__text">{x.label} ({x.unit})</span>
                        </span>
                    ))}
                </div>}
            <div className="chart__container" >
                <div className="chart__container-inner">
                    <Bar ref={chartRef} data={chartData} options={options} />
                </div>
            </div>
        </div>
    )
}
const axisPositions = ["left", "right", "bottom"];

const pointPropType = PropTypes.shape({
    x: PropTypes.any,
    y: PropTypes.any
});
const dataSeriesPropType = PropTypes.shape({
    label: PropTypes.string,
    points: PropTypes.arrayOf(pointPropType),
    unit: PropTypes.string,
    alignToAxis: PropTypes.oneOf(axisPositions),
    type: PropTypes.oneOf(["bar", "line"]).isRequired,
    color: PropTypes.string,
    hidden: PropTypes.bool,
});

const axisPropType = PropTypes.shape({
    position: PropTypes.oneOf(axisPositions).isRequired,
    type: PropTypes.oneOf(["linear", "time"]),
    unit: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    options: PropTypes.object
});

BarAndLineChart.defaultProps = {
    yTickCount: 4,
    showLegend: true,
    precisionOptions: {},
}

BarAndLineChart.propTypes = {
    series: PropTypes.arrayOf(dataSeriesPropType).isRequired,
    axes: PropTypes.arrayOf(axisPropType).isRequired,
    yTickCount: PropTypes.number,
    yUnitsAboveGraph: PropTypes.bool,
    showLegend: PropTypes.bool,
    precision: PropTypes.oneOf(["minute", "hour", "day", "month"]),
    precisionOptions: PropTypes.object,
}


export default injectIntl(BarAndLineChart);

